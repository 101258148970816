import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PORTAL_BACKEND_BASE_URL } from "../../../common/constant";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";
import * as Yup from "yup";
import { useEffect } from "react";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";

export const YEARS_ARRAY = [
  {
    label: "Select Year",
    value: "",
  },
  {
    label: "1970",
    value: "1970",
  },
  {
    label: "1971",
    value: "1971",
  },
  {
    label: "1972",
    value: "1972",
  },
  {
    label: "1973",
    value: "1973",
  },
  {
    label: "1974",
    value: "1974",
  },
  {
    label: "1975",
    value: "1975",
  },
  {
    label: "1976",
    value: "1976",
  },
  {
    label: "1977",
    value: "1977",
  },
  {
    label: "1978",
    value: "1978",
  },
  {
    label: "1979",
    value: "1979",
  },
  {
    label: "1980",
    value: "1980",
  },
  {
    label: "1981",
    value: "1981",
  },
  {
    label: "1982",
    value: "1982",
  },
  {
    label: "1983",
    value: "1983",
  },
  {
    label: "1984",
    value: "1984",
  },
  {
    label: "1985",
    value: "1985",
  },
  {
    label: "1986",
    value: "1986",
  },
  {
    label: "1987",
    value: "1987",
  },
  {
    label: "1988",
    value: "1988",
  },
  {
    label: "1989",
    value: "1989",
  },
  {
    label: "1990",
    value: "1990",
  },
  {
    label: "1991",
    value: "1991",
  },
  {
    label: "1992",
    value: "1992",
  },
  {
    label: "1993",
    value: "1993",
  },
  {
    label: "1994",
    value: "1994",
  },
  {
    label: "1995",
    value: "1995",
  },
  {
    label: "1996",
    value: "1996",
  },
  {
    label: "1997",
    value: "1997",
  },
  {
    label: "1998",
    value: "1998",
  },
  {
    label: "1999",
    value: "1999",
  },
  {
    label: "2000",
    value: "2000",
  },
  {
    label: "2001",
    value: "2001",
  },
  {
    label: "2002",
    value: "2002",
  },
  {
    label: "2003",
    value: "2003",
  },
  {
    label: "2004",
    value: "2004",
  },
  {
    label: "2005",
    value: "2005",
  },
  {
    label: "2006",
    value: "2006",
  },
  {
    label: "2007",
    value: "2007",
  },
  {
    label: "2008",
    value: "2008",
  },
  {
    label: "2009",
    value: "2009",
  },
  {
    label: "2010",
    value: "2010",
  },
  {
    label: "2011",
    value: "2011",
  },
  {
    label: "2012",
    value: "2012",
  },
  {
    label: "2013",
    value: "2013",
  },
  {
    label: "2014",
    value: "2014",
  },
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
  // {
  //   label: "2025",
  //   value: "2025",
  // },
  // {
  //   label: "2026",
  //   value: "2026",
  // },
  // {
  //   label: "2027",
  //   value: "2027",
  // },
  // {
  //   label: "2028",
  //   value: "2028",
  // },
  // {
  //   label: "2029",
  //   value: "2029",
  // },
  // {
  //   label: "2030",
  //   value: "2030",
  // },
];

export const INTEND_YEAR_ARRAY = [
  {
    label: "Select Year",
    value: "",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];

export const INTEND_MONTH_ARRAY = [
  {
    label: "Select Month",
    value: "",
  },
  {
    label: "Jan",
    value: "Jan",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "Sept",
    value: "Sept",
  },
];

const CommonModifiedEventRegForm = ({
  data,
  CommonEventID,
  campaignList,
  campaignTypeList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const EventGUID = params?.StudentID;
  const Campaign = params?.Campaign;

  // console.log("campaign", Campaign);

  const [loading, setLoading] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(true);
  const [docs, setDocs] = useState({
    DegreeMarksheet: "",
    EnglishProficiencyTest: "",
    EntranceExamResult: "",
  });
  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: "",
    TimeToVisitID: null,
    CountryPreference: [],
    CoursePreference: [],
    Degree: "",
    University: "",
    DegreePasingYear: "",
    EventSourceID: "",
    IntakeYear: "",
    IntakeMonth: "",
    Campaign: "",
    CampaignType: "",
    IsCarryDocument: "",
    DocumentName: "",
    DegreeMarksheet: "",
    EnglishProficiencyTest: "",
    EntranceExamResult: "",
  });

  useEffect(() => {
    if (EventGUID) {
      const fetchStudentDetails = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/EventMainForm/StudentDetailsByGUID`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventGUID: EventGUID,
              }),
            }
          );
          const responseData = await response.json();
          if (responseData.Status === 200) {
            const result = responseData?.Data;
            setInitialValues({
              ...initialValues,
              FirstName: result?.FirstName,
              LastName: result?.LastName,
              EmailID: result?.EmailID,
              MobileNo: result?.MobileNo,
              TimeToVisitID: result?.TimeToVisitID,
              ID: result?.ID,
              CountryPreference: result?.CountryPreference
                ? result?.CountryPreference.split(",")?.map(Number)
                : [],
              CoursePreference: result?.CoursePreferences
                ? result?.CoursePreferences.split(",")?.map(Number)
                : [],
              Degree: result?.Degree,
              University: result?.University,
              DegreePasingYear: result?.DegreePasingYear,
              IntakeYear: result?.IntakeYear,
              IntakeMonth: result?.IntakeMonth,
              Campaign: result?.Campaign,
              IsCarryDocument: result?.IsCarryDocument
                ? result?.IsCarryDocument?.toString()
                : "false",
              DocumentName: result?.DocumentName,
            });
            setDocs({
              ...docs,
              DegreeMarksheet: result?.DegreeMarksheet,
              EnglishProficiencyTest: result?.EnglishProficiencyTest,
              EntranceExamResult: result?.EntranceExamResult,
            });
          } else {
            console.log("Failed to fetch Student Details");
          }
        } catch (error) {
          console.log("Error while fetching Student Details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStudentDetails();
    }
  }, [EventGUID]);

  const phoneRegExp = /^[0-9]{6,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
    TimeToVisitID: Yup.number().required("Time to Visit is required"),
    CountryPreference: Yup.array().test(
      "at-least-one",
      "At least one of Country Preference is required",
      function (value) {
        const { CountryPreference } = this.parent;
        return (
          value?.length > 0 ||
          (CountryPreference && CountryPreference?.length > 0)
        );
      }
    ),
    // CoursePreference: Yup.array().test(
    //   "at-least-one",
    //   "At least one of Course Preference is required",
    //   function (value) {
    //     const { CountryPreference } = this.parent;
    //     return (
    //       value?.length > 0 ||
    //       (CountryPreference && CountryPreference?.length > 0)
    //     );
    //   }
    // ),
    Degree: Yup.string().required("Degree is required"),
    University: Yup.string().required("University is required"),
    DegreePasingYear: Yup.string().required("DegreePasingYear is required"),
    IntakeYear: Yup.string().required("Intake Year is required"),
    IntakeMonth: Yup.string().required("Intake Month is required"),
    IsCarryDocument: Yup.string().required("IsCarryDocument is required"),
    // Campaign: Yup.string().required("Campaign is required"),
    Campaign: Yup.string().when([], {
      is: () => Campaign === "walkin",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema,
    }),
    CampaignType: Yup.string().when([], {
      is: () => Campaign === "walkin",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema,
    }),
    DocumentName: Yup.string().when("IsCarryDocument", {
      is: "true",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    DegreeMarksheet: Yup.mixed().when("IsCarryDocument", {
      is: (IsCarryDocument) =>
        IsCarryDocument === "true" && !docs.DegreeMarksheet,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema,
    }),
  });

  const handleCountryChange = (countryID) => {
    const { CountryPreference } = formik.values;
    const updatedPreferences = CountryPreference.includes(countryID)
      ? CountryPreference.filter((ID) => ID !== countryID)
      : [...CountryPreference, countryID];
    formik.setFieldValue("CountryPreference", updatedPreferences);
  };

  const handleCourseChange = (courseID) => {
    const { CoursePreference } = formik.values;
    const updatedPreferences = CoursePreference.includes(courseID)
      ? CoursePreference.filter((ID) => ID !== courseID)
      : [...CoursePreference, courseID];
    formik.setFieldValue("CoursePreference", updatedPreferences);
  };

  const handleRadioChange = async (event, field, formik) => {
    formik.setFieldValue(field, event.target.value);
  };

  const onSubmit = async (values) => {
    if (EventGUID) {
      const editPayload = {
        // ID: values.ID ? +values.ID : null,
        EventGUID: EventGUID,
        FirstName: values?.FirstName ? values?.FirstName : "",
        LastName: values?.LastName ? values?.LastName : "",
        EmailID: values?.EmailID ? values?.EmailID : "",
        MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
        TimeToVisitID: values?.TimeToVisitID ? +values?.TimeToVisitID : "",
        CountryPreference: values?.CountryPreference
          ? values?.CountryPreference?.join(",")
          : "",
        CoursePreference: values?.CoursePreference
          ? values?.CoursePreference?.join(",")
          : "",
        Degree: values?.Degree ? values?.Degree : "",
        University: values?.University ? values?.University : "",
        DegreePasingYear: values?.DegreePasingYear
          ? values?.DegreePasingYear
          : "",
        IntakeYear: values?.IntakeYear ? values?.IntakeYear : "",
        IntakeMonth: values?.IntakeMonth ? values?.IntakeMonth : "",
        IsCarryDocument: values?.IsCarryDocument === "true" ? true : false,
        EventID: CommonEventID ? +CommonEventID : null,
        // StudentGUID:
        //   data?.StudentID?.toLowerCase() === "add"
        //     ? "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        //     : data?.StudentID,
        // Campaign: data.Campaign ? data.Campaign : "website",
        // Campaign:
        //   data?.StudentID?.toLowerCase() === "add"
        //     ? values.Campaign
        //     : data.Campaign
        //     ? data.Campaign
        //     : "website",
      };
      try {
        setLoading(true);
        const response = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/EventMainForm/UpdateEventMainForm`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editPayload),
          }
        );
        const responseData = await response.json();
        if (responseData.Status !== 200) {
          ErrorToast(responseData?.Details);
        } else {
          // CHAGES NEEDED FOR STUDENTID AND ALSO CHANGES ALL THE PAYLOAD
          if (values?.DocumentName) {
            setLoading(true);
            const formData = new FormData();
            formData.append(
              "DegreeMarksheet",
              values.DegreeMarksheet ? values.DegreeMarksheet : ""
            );
            formData.append("EventGUID", EventGUID);
            formData.append(
              "DocumentName",
              values.DocumentName ? values.DocumentName : ""
            );
            if (values?.EnglishProficiencyTest) {
              formData.append(
                "EnglishProficiencyTest",
                values?.EnglishProficiencyTest
              );
            }
            if (values?.EntranceExamResult) {
              formData.append("EntranceExamResult", values?.EntranceExamResult);
            }
            const imageResponse = await fetch(
              `${PORTAL_BACKEND_BASE_URL}/EventMainForm/StudentDocumentUpload`,
              {
                method: "POST",
                body: formData,
              }
            );
            const imageResponseData = await imageResponse.json();
            if (imageResponseData.Status !== 200) {
              setLoading(false);
              ErrorToast(imageResponseData?.Details);
              // return;
            } else {
              setLoading(false);
              setShowSuccessToast(false);
              // SuccessToast(imageResponseData?.Details);
              navigate("/events/thankyou/");
            }
          }
          if (showSuccessToast) {
            SuccessToast(responseData?.Details);
          }
          navigate("/events/thankyou/");
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    } else {
      const insertPayload = {
        EventID: CommonEventID ? CommonEventID : null,
        FirstName: values?.FirstName ? values?.FirstName : "",
        LastName: values?.LastName ? values?.LastName : "",
        MobileNo: values?.MobileNo ? values?.MobileNo?.toString() : "",
        EmailID: values?.EmailID ? values?.EmailID : "",
        TimeToVisitID: values?.TimeToVisitID ? +values?.TimeToVisitID : "",
        CountryPreference: values?.CountryPreference
          ? values?.CountryPreference?.join(",")
          : "",
        CoursePreference: values?.CoursePreference
          ? values?.CoursePreference?.join(",")
          : "",
        Degree: values?.Degree ? values?.Degree : "",
        University: values?.University ? values?.University : "",
        DegreePasingYear: values?.DegreePasingYear
          ? values?.DegreePasingYear
          : "",
        IntakeYear: values?.IntakeYear ? values?.IntakeYear : "",
        IntakeMonth: values?.IntakeMonth ? values?.IntakeMonth : "",
        Campaign: Campaign
          ? Campaign === "walkin"
            ? values?.Campaign
            : Campaign
          : "website",
        IsCarryDocument: values?.IsCarryDocument === "true" ? true : false,
      };
      // console.log("insertPayload", insertPayload);
      try {
        setLoading(true);
        const response = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/EventMainForm/AddEventMainForm`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(insertPayload),
          }
        );
        const responseData = await response.json();
        // console.log("responseData", responseData);
        if (responseData.Status !== 200) {
          ErrorToast(responseData?.Details);
        } else {
          if (responseData?.Data?.EventGUID && values.DocumentName) {
            setLoading(true);
            const formData = new FormData();
            formData.append(
              "DegreeMarksheet",
              values?.DegreeMarksheet ? values?.DegreeMarksheet : ""
            );
            formData.append("EventGUID", responseData?.Data?.EventGUID);
            formData.append(
              "DocumentName",
              values?.DocumentName ? values?.DocumentName : ""
            );
            if (values?.EnglishProficiencyTest) {
              formData.append(
                "EnglishProficiencyTest",
                values?.EnglishProficiencyTest
              );
            }
            if (values?.EntranceExamResult) {
              formData.append("EntranceExamResult", values?.EntranceExamResult);
            }

            const imageResponse = await fetch(
              `${PORTAL_BACKEND_BASE_URL}/EventMainForm/StudentDocumentUpload`,
              {
                method: "POST",
                body: formData,
              }
            );
            const imageResponseData = await imageResponse.json();
            if (imageResponseData.Status !== 200) {
              setLoading(false);
              ErrorToast(imageResponseData?.Details);
              // return;
            } else {
              setLoading(false);
              setShowSuccessToast(false);
              // SuccessToast(imageResponseData?.Details);
              navigate("/events/thankyou/");
            }
          }
          if (showSuccessToast) {
            SuccessToast(responseData?.Details);
          }
          navigate("/events/thankyou/");
          // SuccessToast(responseData?.Details);
          // navigate("/events/thankyou/");
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      {loading && <LoadingSpinner />}
      {/* heading */}
      {/* <div className="crms-title row mb-4">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.CMSHeader,
          }}
        />
      </div> */}
      {/* card rows form starting */}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6 d-flex">
            <div
              className="card flex-fill"
              style={{ border: "1px solid goldenrod" }}
            >
              <div className="card-header event-card-header">
                <h5 className="card-title mb-0">Basic Information</h5>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      First Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="FirstName"
                        type="text"
                        id="FirstName"
                        className={`form-control ${
                          formik.errors.FirstName &&
                          formik.touched.FirstName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FirstName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Last Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="LastName"
                        type="text"
                        id="LastName"
                        className={`form-control ${
                          formik.errors.LastName &&
                          formik.touched.LastName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.LastName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Email Address<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="EmailID"
                        type="email"
                        id="EmailID"
                        className={`form-control ${
                          formik.errors.EmailID &&
                          formik.touched.EmailID &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.EmailID}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Mobile No<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="MobileNo"
                        type="number"
                        id="MobileNo"
                        className={`form-control ${
                          formik.errors.MobileNo &&
                          formik.touched.MobileNo &&
                          "error-border"
                        }`}
                        max={9999999999}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.MobileNo}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Time to Visit<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <select
                        id="TimeToVisitID"
                        name="TimeToVisitID"
                        className={`form-control ${
                          formik.errors.TimeToVisitID &&
                          formik.touched.TimeToVisitID &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.TimeToVisitID}
                      >
                        <option value={null} label={"Select Time"} />
                        {data?.TimeSlotList?.map((item) => {
                          return (
                            <option value={item?.ID} label={item?.TimeSlot} />
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Country Preference<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <table
                        className={`form-control tabel-border-none ${
                          formik.errors.CountryPreference &&
                          formik.touched.CountryPreference &&
                          "error-border"
                        }`}
                        id="CountryPreference"
                      >
                        <tbody>
                          {data?.EventContry?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={formik.values.CountryPreference.includes(
                                      item.ID
                                    )}
                                    onChange={() =>
                                      handleCountryChange(item.ID)
                                    }
                                  />
                                  {item.CountryName}
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Course Preference<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <table
                        className={`form-control tabel-border-none ${
                          formik.errors.CoursePreference &&
                          formik.touched.CoursePreference &&
                          "error-border"
                        }`}
                        id="CoursePreference"
                      >
                        <tbody>
                          {data?.EventCourse?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={formik.values.CoursePreference?.includes(
                                      item.ID
                                    )}
                                    onChange={() => handleCourseChange(item.ID)}
                                  />
                                  {item.CourseName}
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mt-4 mt-xl-0">
            <div
              className="card mb-0"
              style={{ border: "1px solid goldenrod" }}
            >
              <div className="card-header event-card-header">
                <h5 className="card-title mb-0">Educational Details</h5>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-lg-12 mb-4">
                        <h6 className="card-title mb-0">Degree</h6>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Degree<code>*</code>
                        </label>
                        <div className="col-lg-8">
                          <input
                            name="Degree"
                            type="text"
                            id="Degree"
                            className={`form-control ${
                              formik.errors.Degree &&
                              formik.touched.Degree &&
                              "error-border"
                            }`}
                            maxlength="50"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Degree}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          University<code>*</code>
                        </label>
                        <div className="col-lg-8">
                          <input
                            name="University"
                            type="text"
                            id="University"
                            className={`form-control ${
                              formik.errors.University &&
                              formik.touched.University &&
                              "error-border"
                            }`}
                            maxlength="50"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.University}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Passing Year<code>*</code>
                        </label>
                        <div className="col-lg-8">
                          <select
                            id="DegreePasingYear"
                            name="DegreePasingYear"
                            className={`form-control ${
                              formik.errors.DegreePasingYear &&
                              formik.touched.DegreePasingYear &&
                              "error-border"
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DegreePasingYear}
                          >
                            {YEARS_ARRAY.map((item) => {
                              return (
                                <option value={item.value} label={item.label} />
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card mt-3 mb-0 p-3"
              style={{ border: "1px solid goldenrod" }}
            >
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  Course Preference
                </label>
                <div className="col-lg-8">
                  <table
                    style={{
                      height: 175,
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                    }}
                    className={`form-control tabel-border-none ${
                      formik.errors.CoursePreference &&
                      formik.touched.CoursePreference &&
                      "error-border"
                    }`}
                    id="CoursePreference"
                  >
                    <tbody>
                      {data?.EventCourse?.map((item) => (
                        <tr key={item.ID}>
                          <td>
                            <label>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={formik.values.CoursePreference?.includes(
                                  item.ID
                                )}
                                onChange={() => handleCourseChange(item.ID)}
                              />
                              {item.CourseName}
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 ">
            <div
              className="card mb-0"
              style={{ border: "1px solid goldenrod" }}
            >
              <div className="card-header event-card-header">
                <h5 className="card-title mb-0">Other details</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Intended Intake<code>*</code>
                    </label>
                    <div className="col-lg-4">
                      <select
                        id="IntakeYear"
                        name="IntakeYear"
                        className={`form-control ${
                          formik.errors.IntakeYear &&
                          formik.touched.IntakeYear &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IntakeYear}
                      >
                        {INTEND_YEAR_ARRAY.map((item) => {
                          return (
                            <option value={item.value} label={item.label} />
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg-4 mt-3 mt-lg-0">
                      <select
                        id="IntakeMonth"
                        name="IntakeMonth"
                        className={`form-control ${
                          formik.errors.IntakeMonth &&
                          formik.touched.IntakeMonth &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IntakeMonth}
                      >
                        {INTEND_MONTH_ARRAY.map((item) => {
                          return (
                            <option value={item.value} label={item.label} />
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Campaign === "walkin" && (
          <div className="row mt-4">
            <div className="col-md-12">
              <div
                className="card mb-0"
                style={{ border: "1px solid goldenrod" }}
              >
                <div className="card-header event-card-header">
                  <h5 className="card-title mb-0">
                    How did you come to know about this event?
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4 mt-3 mt-xl-0">
                      <select
                        id="Lead Source"
                        name="CampaignType"
                        className={`form-control ${
                          formik.errors.CampaignType &&
                          formik.touched.CampaignType &&
                          "error-border"
                        }`}
                        disabled={EventGUID}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("Campaign", "");
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.CampaignType}
                      >
                        <option value={""} label={"Select Lead Source"} />
                        {campaignTypeList?.map((item) => {
                          return (
                            <option value={item.value} label={item.label} />
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg-4 mt-3 mt-xl-0">
                      <select
                        id="Campaign"
                        name="Campaign"
                        className={`form-control ${
                          formik.errors.Campaign &&
                          formik.touched.Campaign &&
                          "error-border"
                        }`}
                        disabled={EventGUID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          !formik.values.Campaign
                            ? data?.Campaign
                            : formik.values.Campaign
                        }
                      >
                        <option value={""} label={"Select Lead Sub Source"} />
                        {campaignList
                          ?.filter(
                            (e) =>
                              e.CampaignTypeID === +formik.values.CampaignType
                          )
                          ?.map((item) => {
                            return (
                              <option
                                value={item?.CampaignURL}
                                label={item?.label}
                              />
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row mt-4">
          <div className="col-md-12 ">
            <div
              className="card mb-0"
              style={{ border: "1px solid goldenrod" }}
            >
              <div className="card-header event-card-header">
                <h5 className="card-title mb-0">Documents</h5>
              </div>
              <div className="card-body">
                <div className="col-lg-12 mt-3 mt-xl-0 fw-bold">
                  To meet University Representative, Academic documents are
                  required.
                </div>
                <div className="col-lg-12 mt-3 mt-xl-0">
                  <span
                    className={`me-5 ${
                      formik.errors.IsCarryDocument &&
                      formik.touched.IsCarryDocument &&
                      "error-label"
                    }`}
                  >
                    Would you like to upload documents?
                  </span>
                  <label
                    className={`me-3 ${
                      formik.errors.IsCarryDocument &&
                      formik.touched.IsCarryDocument &&
                      "error-label"
                    }`}
                  >
                    <input
                      className="me-2"
                      type="radio"
                      name="IsCarryDocument"
                      onChange={(event) =>
                        handleRadioChange(event, "IsCarryDocument", formik)
                      }
                      onBlur={formik.handleBlur}
                      value={"true"}
                      checked={formik.values.IsCarryDocument === "true"}
                    />
                    Yes
                  </label>
                  <label
                    className={`me-3 ${
                      formik.errors.IsCarryDocument &&
                      formik.touched.IsCarryDocument &&
                      "error-label"
                    }`}
                  >
                    <input
                      className="me-2"
                      type="radio"
                      name="IsCarryDocument"
                      onChange={(event) =>
                        handleRadioChange(event, "IsCarryDocument", formik)
                      }
                      onBlur={formik.handleBlur}
                      value={"false"}
                      checked={formik.values.IsCarryDocument === "false"}
                    />
                    No
                  </label>
                </div>
                {formik.values.IsCarryDocument === "true" && (
                  <div className="col-12 mt-3">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label fw-bold">
                        Last Academic Marksheet
                      </label>
                      <div className="col-lg-4 mb-2">
                        <input
                          name="DocumentName"
                          type="text"
                          id="DocumentName"
                          placeholder="Documen Title"
                          className={`form-control ${
                            formik.errors.DocumentName &&
                            formik.touched.DocumentName &&
                            "error-border"
                          }`}
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.DocumentName}
                        />
                      </div>
                      <div className="col-lg-4 text-end">
                        <input
                          type="file"
                          id="DegreeMarksheet"
                          className={`form-control ${
                            formik.errors.DegreeMarksheet &&
                            formik.touched.DegreeMarksheet &&
                            "error-border"
                          }`}
                          name="DegreeMarksheet"
                          onChange={(event) =>
                            formik.setFieldValue(
                              "DegreeMarksheet",
                              event.target.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {docs.DegreeMarksheet && (
                          <Link to={docs.DegreeMarksheet} target="_blank">
                            View Document
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {formik.values.IsCarryDocument === "true" && (
                  <div className="col-12">
                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label fw-bold"
                        style={{ padding: "0 0 10px 13px" }}
                      >
                        English Proficiency Test result{" "}
                        <span style={{ fontSize: 11 }}>
                          i.e. IELTS / PTE / Duolingo / TOEFL
                        </span>
                      </label>
                      <div className="col-lg-4 text-end">
                        <input
                          type="file"
                          id="EnglishProficiencyTest"
                          className={`form-control ${
                            formik.errors.EnglishProficiencyTest &&
                            formik.touched.EnglishProficiencyTest &&
                            "error-border"
                          }`}
                          name="EnglishProficiencyTest"
                          onChange={(event) =>
                            formik.setFieldValue(
                              "EnglishProficiencyTest",
                              event.target.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {docs.EnglishProficiencyTest && (
                          <Link
                            to={docs.EnglishProficiencyTest}
                            target="_blank"
                          >
                            View Document
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label fw-bold "
                        style={{ padding: "0 0 10px 13px" }}
                      >
                        Entrance Exam result{" "}
                        <span style={{ fontSize: 11 }}>i.e. GRE / SAT</span>
                      </label>
                      <div className="col-lg-4 text-end">
                        <input
                          type="file"
                          id="EntranceExamResult"
                          className={`form-control ${
                            formik.errors.EntranceExamResult &&
                            formik.touched.EntranceExamResult &&
                            "error-border"
                          }`}
                          name="EntranceExamResult"
                          onChange={(event) =>
                            formik.setFieldValue(
                              "EntranceExamResult",
                              event.target.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {docs.EntranceExamResult && (
                          <Link to={docs.EntranceExamResult} target="_blank">
                            View Document
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {formik.values.IsCarryDocument === "false" && (
                  <div
                    className="fw-bold text-danger mt-3"
                    style={{ fontSize: 17 }}
                  >
                    Bring documents at the event venue.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mt-3 mb-3" style={{ textAlign: "end" }}>
          <div className="col-lg-12">
            <button
              id="submit-btn"
              className="submit-btn"
              type="submit"
              disabled={loading}
            >
              {loading ? "Submiting" : "Submit Details"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommonModifiedEventRegForm;
