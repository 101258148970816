export const BASE_URL = "http://localhost:3000/";
export const LIVE_URL = "https://website.careerline.in/";

// LIVE_URL
export const BACKEND_BASE_URL = "https://webapi.careerline.org/api";
export const PORTAL_BACKEND_BASE_URL = "https://careerline.in/api";

//DEMO_URL
// export const BACKEND_BASE_URL = "https://websiteapi.careerline.in/api";
// export const PORTAL_BACKEND_BASE_URL = "https://api.careerline.in/api";

export const STATUS_ARRAY = [
  {
    label: "Exam Not Given",
    value: 1,
  },
  {
    label: "Score Ready",
    value: 2,
  },
  {
    label: "Exam Given",
    value: 3,
  },
  {
    label: "Date Taken",
    value: 4,
  },
];
