import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const BannerSlider = ({ bannerdata }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div
      className="col-sm-12 col-md-12 col-lg-6 plan-pic"
      data-aos="fade-left"
      data-aos-duration="700"
    >
      <div className="bannerhomefirst">
        <Slider {...sliderSettings}>
          {/* <div
            className="item"
            dangerouslySetInnerHTML={{ __html: bannerdata?.Banner }}
          /> */}
          {bannerdata?.BannerList?.map((item) => (
            <div dangerouslySetInnerHTML={{ __html: item?.Banner }} />
          ))}





          {/* <div className="item">
            <div className="custom-banner-home">
          
              <div className="students-pic">
                <img src={stunden} alt="" />
              </div>
              <div className="box-images leaf">
                <img src={leaf} alt="" />
              </div>
              <div className="box-images bank">
                <img src={bank} alt="" />
              </div>
              <div className="box-images microsope">
                <img src={microsope} alt="" />
              </div>
              <div className="bannercustomdata">
                <img src={ukCounty} alt="" />
                <h4>Keerthi Madeswaran</h4>
                <p>MS in STRUCTURAL ENGINEERING</p>
                <span className="redtext">IMPERIAL COLLEGE LONDON</span>
                <strong>Fall 2022 intake</strong>
              </div>
              <div className="student-countrylist">
                <div>
                  <p>100+ Student Abroad</p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <img src={usaFlag} alt="" />
                  <img src={ukFlag} alt="" />
                  <img src={canadaFlag} alt="" />
                  <img src={germanyFlag} alt="" />
                  <span className="pluscounty">6+</span>
                </div>
              </div>
              <img src={blueFlaf} alt="" />
            </div>
          </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default BannerSlider;
