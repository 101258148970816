import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const DestinationSlider = ({ destinationData }) => {
  const navigate = useNavigate();
  const handleReadMore = (url) => {
    navigate(url);
  };

  const renderDestinations = () => {
    return destinationData.map((destination, index) => (
      // console.log("imgdestination",destinationDestinationImg)
      <div className="owl-carousel dream owl-theme">
        <div key={index} className="item" style={{ marginRight: "10px" }}>
          <div
            className="dream-para"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay={index * 200}
          >
            <div className="dream-img-large">
              <img src={destination.DestinationImg} alt="" />
            </div>
            <div className="dream-data-nation">
              <div className="dream-img-nation">
                <img src={destination.DestinationLogo} alt="" />
              </div>
              <h5>{destination.Title}</h5>
              <p>{destination.Description}</p>
              <div className="search-red">
                <a
                  onClick={() =>
                    handleReadMore(`${destination.DestinationURL}/`)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  {/* <img src={searchIcon} alt="searchlogo" /> */}
                  View More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="dream-section">
        <div className="dream-head" data-aos="fade-up" data-aos-duration="700">
          <h2>
            Select Your Dream <span>Destination</span>
          </h2>
          <p>We are partnered with 600+ Institutions all over the world</p>
        </div>

        <Slider {...settings} className="dream-slider">
          {renderDestinations()}
        </Slider>
      </div>
    </div>
  );
};

export default DestinationSlider;
