import Route from "./Routes";
import "./App.css";
import React from "react";

function App() {
  return (
    <React.Fragment className="App">
      <Route />
    </React.Fragment>
  );
}

export default App;
